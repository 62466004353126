import React, { useState, useEffect, useRef } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { fetchClients, fetchFilesStats, dialerStats } from "../../utils/api";
import { useQuery } from "react-query";
import WalkThroughCard from "../../components/WalkthroughCard";
import Header from "../../components/Header";
import profile from "../../imgs/profile-cropped.svg";
import success from "../../imgs/success-cropped.svg";

export default function DialerStats() {
  const stepperRef = useRef(null);
  const warning = useRef(null);
  const [client, setClient] = useState("");
  const [clientFiles, setClientFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileDetails, setFileDetails] = useState(false);
  const [uploaded, setUploaded] = useState("");
  const [DNC, setDNC] = useState("");
  const [duplicates, setDeduplicates] = useState("");
  const [emptyValueIndices, setEmptyValueIndices] = useState([]);
  const [badNumbers, setBadNumber] = useState("");
  const [statsDetails, setStatsDetails] = useState(false);
  const [statsReceived, setStatsReceived] = useState(false);
  const [dropDisable, setDropDisable] = useState(true);
  const inputs = [
    { name: "Uploaded", placeholder: "e.g. 100", type: "Integer", key: "" },
    { name: "Do Not Calls", placeholder: "e.g. 100", type: "Integer", key: "" },
    { name: "Duplicates", placeholder: "e.g. 100", type: "Integer", key: "" },
    { name: "Bad Numbers", placeholder: "e.g. 100", type: "Integer", key: "" },
  ];
  //get client files
  useEffect(() => {
    async function retrieveClientFiles(client) {
      try {
        const files = await fetchFilesStats(client);
        setClientFiles(files);
        setFilesLoading(false);
        setDropDisable(false);
      } catch (error) {
        console.error("Error fetching client files:", error);
      }
    }
    if (client) {
      retrieveClientFiles(client);
    }
  }, [client]);

  //get clients
  const { data: clients, isLoading: clientsLoading } = useQuery(
    "fetchClients",
    fetchClients,
    {
      onSuccess: () => {
        console.log("Clients are here");
      },
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
    }
  );
  //Warning in case clicking next without entering required data
  const showWarn = () => {
    warning.current.show({
      severity: "warn",
      summary: "Warning",
      detail: " Please Enter Required Data To Proceed",
      life: 3000,
    });
  };
  // Handle clicking on next
  const handleNextClick = (current_step, state, ref) => {
    console.log(current_step);
    if (state) {
      ref.current.nextCallback();
    } else {
      showWarn();
    }
  };
  //
  const UploadStats = async () => {
    const formData = new FormData();
    formData.append("file_id", fileName.file_id);
    formData.append("successful_uploads", uploaded);
    formData.append("bad_phone_numbers", badNumbers);
    formData.append("duplicates", duplicates);
    formData.append("do_not_calls", DNC);
    console.log("here");
    const response = dialerStats(formData);
    console.log("done");
    setStatsReceived(true);
    console.log(response);
  };
  //confirm
  const confirmStats = () => {
    // Regular expression to match the format number-number

    let emptyFieldNames = emptyValueIndices
      .map((val) => inputs[val].name)
      .join(", ");
    if (emptyFieldNames !== "") {
      warning.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `Please fill ${emptyFieldNames} to proceed`,
        life: 3000,
      });
      setEmptyValueIndices([]);
      return false;
    } else {
      setEmptyValueIndices([]);
      if (statsDetails) {
        UploadStats();
      }
    }
  };
  return (
    <div>
      <Header
        username={localStorage.getItem("client_name")}
        profileLink="/data-options"
        upload={true}
        profileImage={profile}
      />
      <Toast ref={warning} />
      <Stepper ref={stepperRef} linear style={{ flexBasis: "50rem" }}>
        <StepperPanel header="File Details">
          <div>
            <WalkThroughCard
              dropDowns={true}
              menus={[
                {
                  name: "Clients's name",
                  placeholder: "Enter Client Name",
                  option: "full_name",
                },
                {
                  name: "File name",
                  placeholder: "Enter File Name",
                  option: "file_name",
                },
              ]}
              drop_options={[clients, clientFiles]}
              disable={[false, dropDisable]}
              onSet={setFileDetails}
              functions={[setClient, setFileName]}
              fetching={[clientsLoading, filesLoading]}
              onChoice={setFilesLoading}
              activeIdx={0}
              totalIdx={2}
              question={<span></span>}
            />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("file details", fileDetails, stepperRef);
                }}
              />
            </div>
          </div>
        </StepperPanel>
        <StepperPanel header="Stats">
          {statsReceived ? (
            <WalkThroughCard
              final={true}
              question={
                <span>The stats have been successfully sent to the client</span>
              }
              destination={"/data-options"}
              uploadSuccessful={true}
              finalButton={"Go Home"}
              img={success}
            />
          ) : (
            <WalkThroughCard
              description={true}
              alone={true}
              question={<span className="font-bold"> File Stats </span>}
              inputs={inputs}
              functions={[setUploaded, setDNC, setDeduplicates, setBadNumber]}
              final={true}
              confirm={confirmStats}
              emptyValueIndices={emptyValueIndices}
              finalButton={"Confirm"}
              uploadSuccessful={true}
              destination={"/data-options"}
              onSet={setStatsDetails}
            />
          )}
          <div className="flex pt-4 justify-content-start">
            <Button
              label="Back"
              severity="secondary"
              icon="pi pi-arrow-left"
              onClick={() => {
                stepperRef.current.prevCallback();
                setClient("");
                setFileName("");
                setFileDetails(false);
              }}
            />
          </div>
        </StepperPanel>
      </Stepper>
    </div>
  );
}
