import React, { useState, useRef } from "react";
import profile from "../../imgs/profile-cropped.svg";
import RegistrationCard from "../../components/RegistrationCard";
import PassIcon from "../../imgs/change-password.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Toast } from "primereact/toast";
import { updatePassword } from "../../utils/api";
import { Dialog } from "primereact/dialog";
import "../../styling/Custom.css";
import Header from "../../components/Header";
export default function ChangePassword() {
  const warning = useRef(null);
  const [password, setPassword] = useState("");
  const [current, setCurrent] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const passwordFields = [
    {
      value: current,
      onChange: (e) => setCurrent(e.target.value),
      placeholder: "Current Password",
      feedback: false,
      disabled: loading,
    },
    {
      value: password,
      onChange: (e) => setPassword(e.target.value),
      placeholder: "New Password",
      feedback: true,
      disabled: loading,
    },
    {
      value: confirmPassword,
      onChange: (e) => setConfirmPassword(e.target.value),
      placeholder: "Confirm New Password",
      feedback: false,
      disabled: loading,
    },
  ];
  const handlePasswordUpdate = async () => {
    //   const response = await signIn(email, password);
    if (password === "" || current === "" || confirmPassword === "") {
      warning.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `Please fill all fields to proceed`,
        life: 3000,
      });
    } else if (password !== confirmPassword) {
      warning.current.show({
        severity: "error",
        summary: "Error",
        detail: `passwords do not match`,
        life: 3000,
      });
      setPassword("");
      setConfirmPassword("");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("client_id", localStorage.getItem("client_id"));
      formData.append("old_password", current);
      formData.append("new_password", password);
      const response = await updatePassword(formData);
      if (response.success) {
        notify();
        navigate("/options");
      } else {
        setShowError(true);
        if (response.data === "Wrong password.") {
          setErrorMessage("The current password you entered is incorrect.");
        } else {
          setErrorMessage(response.data);
        }
      }
      console.log(response);
      setLoading(false);
    }
  };
  //notification when upload is successful
  const notify = () => {
    toast.success("Your password has been changed successfully", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  //   const showErorr = () => {
  //     toast.error(
  //       <span>
  //         We couldn't change your password. Please try again later. If the problem
  //         continues,
  //         <a
  //           href="/contact-us"
  //           className="underline text-blue-600 hover:text-blue-800"
  //         >
  //           contact us
  //         </a>
  //         .
  //       </span>,
  //       {
  //         position: "top-right",
  //         autoClose: false,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       }
  //     );
  //   };
  return (
    <div className="mb-4">
      <Toast ref={warning} />
      <Header
        username={localStorage.getItem("firstName")}
        profileLink="/options"
        profileImage={profile}
        onlyProfile={true}
        titleImage={PassIcon}
        title={"Change Your Password"}
      />
      <div className="card flex justify-content-center">
        <RegistrationCard
          title={""}
          buttonText={"Update Password"}
          inputFields={[]}
          passwordFields={passwordFields}
          onSignIn={handlePasswordUpdate}
          destination={"/options"}
          loading={loading}
        />
      </div>
      <Dialog
        header="Error"
        visible={showError}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!showError) return;
          setShowError(false);
          setCurrent("");
          setPassword("");
          setConfirmPassword("");
        }}
        dismissableMask
      >
        <p>{errorMessage}</p>
      </Dialog>
    </div>
  );
}
