import React, { useState } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Tooltip } from "primereact/tooltip";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import Table from "../Table";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { fetchWorkerDb } from "../../utils/api";
import Header from "../../components/Header";
import profile from "../../imgs/profile-cropped.svg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import "../../styling/Custom.css";
import Loading from "../../components/Loading";
export default function WorkerTable() {
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState("");
  const { data: db, isLoading: dbLoading } = useQuery(
    "fetchWorkerDb",
    fetchWorkerDb,
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
    }
  );
  console.log(db);
  const getSeverity = (status) => {
    switch (status) {
      case "Pending Cleaning":
        return "danger";

      case "Uploaded To Dialer":
        return "success";

      case "Pending Skip Tracing":
        return "info";

      case "Pending Dialer Upload":
        return "warning";

      case "Cleaned & Pending Upload To Dialer":
        return "help";

      case "Skip Traced & Pending Upload To Dialer":
        return null;
    }
  };

  const fileBodyTemplate = (rowData) => {
    // If it's a status group, just show the status label
    if (rowData.data.status) {
      return (
        <>
          <Tooltip target=".noFiles" position="top" />

          <Tag
            className="noFiles"
            data-pr-tooltip={`No of Files ${rowData.data.fileCount}`}
            value={rowData.data.status}
            severity={getSeverity(rowData.status)}
          />
          {/* <span>{`${rowData.data.status}`}</span> */}
        </>
      );
    }

    // If it's a file with a download link
    if (rowData.data.file !== "Files" && rowData.data.detail !== "") {
      //click on file to download
      return (
        <>
          <Tooltip target=".download-link" position="top" />
          <a
            href={rowData.data.detail}
            download
            className="download-link"
            data-pr-tooltip="Download File"
          >
            <i className="pi pi-download" style={{ marginRight: ".5em" }}></i>
            {rowData.data.file}
          </a>
        </>
      );
    }

    // If it's a file but it requires additional processing (no download link)
    if (rowData.data.file !== "Files" && rowData.data.detail === "") {
      return (
        <>
          <Tooltip target=".no-download-link" position="top" />
          <span
            className="no-download-link"
            data-pr-tooltip="File Requires Additional Processing"
          >
            {rowData.data.file}
          </span>
        </>
      );
    }

    // Grouped files
    return (
      <>
        <Tooltip target=".noFiles" position="top" />

        <span
          className="noFiles"
          data-pr-tooltip={`No of Files ${rowData.data.fileCount}`}
        >
          {rowData.data.file}
        </span>
      </>
    );
  };

  //file details
  const detailsTemplate = (rowData) => {
    const handleDetailsClick = () => {
      handleButton(rowData);
    };
    console.log(rowData);
    return (
      <>
        {rowData.data.file !== "Files" && !rowData.data.status && (
          <button
            className="rounded-full bg-blue-500 text-white flex justify-start items-start h-full p-2"
            onClick={handleDetailsClick}
          >
            Go To File Details
          </button>
        )}
      </>
    );
  };
  const handleButton = (rowData) => {
    navigate("/file", {
      state: {
        fileID: rowData.data.file_id,
        client_id: rowData.data.client_id,
      },
    });
  };
  return (
    <div>
      <Header
        username="RES"
        profileLink="/data-options"
        profileImage={profile}
        options={false}
      />
      <TabView className=" custom-tabview justfy-content-start">
        <TabPanel header="Grouped by Client's Name">
          <InputText
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "1rem",
              width: "100%",
            }}
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            className="p-inputtext-lg"
            size={40}
            placeholder="Search ..."
          />
          {!dbLoading ? (
            <TreeTable
              value={db}
              globalFilter={globalFilter}
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                field="name"
                header="Client Name"
                className="w-[200px]"
              ></Column>
              <Column
                body={fileBodyTemplate}
                field="file"
                header="Files"
                expander
                className="text-center break-words"
              ></Column>
              <Column
                body={detailsTemplate}
                header="File Details"
                className="w-[300px]"
              ></Column>
            </TreeTable>
          ) : (
            <Loading message="we're retrieving your data" />
          )}{" "}
        </TabPanel>
        <TabPanel header="Sortable by Upload Date">
          <Table worker={true} />
        </TabPanel>
      </TabView>
    </div>
  );
}
