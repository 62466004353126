import React from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import "../styling/Custom.css";

export default function RegistrationCard(props) {
  return (
    <div className="flex flex-col rounded-3xl mt-[2%] mb-[2%] items-center justify-center h-full min-h-[250px] min-w-[300px] w-[50%] p-3 bg-gray-200 shadow-sm shadow-black mx-auto">
      <h2 className="font-bold text-[20px] m-1 p-2">{props.title}</h2>
      {props.inputFields &&
        props.inputFields.map((field, index) => (
          <InputText
            className="flex flex-col rounded-xl border-none p-2 w-3/4 m-[0.25rem] text-[16px] text-black font-lighter hover:text-black"
            key={index}
            value={field.value}
            onChange={field.onChange}
            placeholder={field.placeholder}
          />
        ))}
      {props.passwordFields &&
        props.passwordFields.map((field, index) => (
          <Password
            className="flex flex-col rounded-xl border-none p-2 w-3/4 m-[0.25rem] text-[16px] text-black font-lighter hover:text-black p-inputtext p-component"
            inputClassName="flex flex-col  w-3/4 rounded-xl border-spacing-1 text-[16px] text-black font-lighter hover:text-black"
            key={index}
            value={field.value}
            onChange={field.onChange}
            placeholder={field.placeholder}
            feedback={field.feedback}
            disabled={field.disabled ? field.disabled : false}
            toggleMask
          />
        ))}
      {props.loading && (
        <div className="card flex justify-content-center">
          <ProgressSpinner
            style={{ width: "20px", height: "20px", margin: "12px" }}
          />
        </div>
      )}
      <Button
        className=" bg-[#8de0ff] rounded-md border-none p-2 px-4 m-2 text-[18px] text-black font-bold hover:bg-blue-300 hover:text-black text-center"
        onClick={props.onSignIn}
      >
        {props.buttonText}
      </Button>
    </div>
  );
}
