import React, { useState, useRef } from "react";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import db from "../imgs/db-cropped.svg";
import fingerprint from "../imgs/fingerprint-cropped.svg";
import { useAuth } from "../Authorization/AuthContext";
import { useNavigate } from "react-router-dom";
import { removeTags } from "../utils/api";

export default function Header(props) {
  const { logout, role } = useAuth();
  const navigate = useNavigate();
  //   const [visible, setVisible] = useState(false);
  const [support, setSupport] = useState(false);
  const [updateTags, setUpdateTags] = useState(false);
  const menu = useRef(null);
  const avatarStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  };

  const items = [
    {
      label: "Home",
      icon: "pi pi-home",
      command: () => {
        role === "user" ? navigate("/options") : navigate("/data-options");
      },
    },
    ...(role === "user"
      ? [
          {
            label: "Edit Preferences",
            icon: "pi pi-cog",
            command: () => {
              navigate("/setUp", { state: { edit: true } });
            },
          },
          {
            label: "Change Password",
            icon: "pi pi-file-edit",
            command: () => {
              navigate("/settings/password");
            },
          },
          {
            label: "Support",
            icon: "pi pi-question-circle",
            command: () => {
              setSupport(true);
            },
          },
        ]
      : [
          {
            label: "Edit campaign name for a client",
            icon: "pi pi-file-edit",
            command: () => {
              navigate("/edit-campaign");
            },
          },
          {
            label: "Update System Tags",
            icon: "pi pi-tags",
            command: () => {
              setUpdateTags(true);
            },
          },
        ]),
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: () => {
        handleLogout();
      },
    },
  ];
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row grid-flow-row justify-between items-center mb-1 mt-1">
      <div className="flex justify-between items-center w-full p-3">
        {!props.file &&
          !props.options &&
          !props.upload &&
          !props.onlyProfile &&
          !props.setup && (
            <div className="flex flex-auto items-center">
              <h2 className="lg:text-6xl md:text-5xl sm:text-4xl xs:text-4xl font-light lg:mr-4 xl:mr-4 md:mr-4 xs:mr-0 sm:mr-0">
                {props.username}'s Database
              </h2>
              <img
                src={db}
                alt="Database"
                className="max-w-12 h-auto mr-4 xs:hidden md:block"
              />
            </div>
          )}
        {props.file && !props.options && !props.upload && (
          <div className="flex flex-auto items-center">
            <h2 className="lg:text-6xl md:text-5xl sm:text-4xl xs:text-4xl font-lightlg:mr-4 xl:mr-4 md:mr-4 xs:mr-0 sm:mr-0">
              {props.username} File Details
            </h2>
            <img
              src={db}
              alt="Database"
              className="max-w-12 h-auto mr-4 xs:hidden md:block"
            />
          </div>
        )}
        {props.setup && (
          <div className="flex flex-auto items-center">
            <h2 className="lg:text-6xl md:text-5xl sm:text-4xl xs:text-3xl font-light">
              {props.title}
            </h2>
          </div>
        )}
        {!props.file && props.options && !props.upload && (
          <div className="flex flex-auto items-center">
            {props.options && (
              <h2 className="lg:text-6xl md:text-5xl sm:text-4xl xs:text-4xl font-light lg:mr-4 xl:mr-4 md:mr-4 xs:mr-0 sm:mr-0">
                Welcome, {props.username}
              </h2>
            )}
            <img
              src={fingerprint}
              alt="Fingerprint "
              className="max-w-12 h-auto mr-4 xs:hidden md:block"
            />
          </div>
        )}
        {!props.file && !props.options && props.upload && (
          <div className="flex flex-auto items-center">
            <h2 className="lg:text-6xl md:text-5xl sm:text-4xl xs:text-4xl font-light">
              Ready to Upload?
            </h2>
          </div>
        )}
        {props.onlyProfile && (
          <div className="flex flex-auto items-center">
            <h2 className="lg:text-6xl md:text-5xl sm:text-4xl xs:text-4xl font-light lg:mr-4 xl:mr-4 md:mr-4 xs:mr-0 sm:mr-0">
              {props.title}
            </h2>
            {props.title && props.titleImage && (
              <img
                src={props.titleImage}
                alt="Fingerprint"
                className="max-w-12 h-auto mr-4 xs:hidden md:block"
              />
            )}
          </div>
        )}

        <div className="flex flex-row items-end justify-end p-3 ml-auto">
          <a
            href={role === "user" ? "/options" : "/data-options"}
            className="flex items-center no-underline text-inherit p-4 text-lg"
          >
            <span className="ml-2 text-base">Home</span>
          </a>

          <Avatar
            image={props.profileImage}
            shape="circle"
            style={avatarStyle}
            onClick={(event) => menu.current.toggle(event)}
            className="m-2 avatar"
          />
          <Menu model={items} popup ref={menu} />
        </div>
      </div>

      <Dialog
        header="Contact us"
        visible={support}
        onHide={() => {
          if (!support) return;
          setSupport(false);
        }}
        style={{ width: "50vw", color: "#23455B" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="flex justify-center items-center m-2 mb-4">
          <img
            src={
              "https://res-data-space.fra1.cdn.digitaloceanspaces.com/icons/res-logo-removebg.png"
            }
            alt="logo"
            className='className="w-[100px] h-[100px] flex justify-center m-[70] p-[40]"'
          />
        </div>
        <p className="m-0">
          We are committed to providing you with the best support experience
          possible. Please click the button below to be redirected to our issue
          submission form. Our team will get back to you as soon as possible.
        </p>
        <div className="flex justify-center items-center m-2 mt-4">
          <Button
            className="bg-[#23455B] rounded-full p-2 px-4 hover:bg-[#168EC2] text-white font-semibold mt-2s"
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            Send Message
          </Button>
        </div>
      </Dialog>
      <Dialog
        header="Warning"
        visible={updateTags}
        onHide={() => {
          if (!updateTags) return;
          setUpdateTags(false);
        }}
        style={{ width: "50vw", color: "#23455B" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <p className="m-0">
          This action will permanently delete all expired tags from the system.
          Do you want to continue?
        </p>
        <div className="flex justify-center items-center gap-3 m-2 mt-4">
          <Button
            label="No"
            icon="pi pi-times"
            className=" rounded-full p-2 px-4 p-button-text text-[#009ccf]  font-semibold mt-2s"
            onClick={() => {
              setUpdateTags(false);
            }}
          ></Button>
          <Button
            label="Yes"
            icon="pi pi-check"
            className="rounded-full p-2 px-4  font-semibold mt-2s bg-[#009ccf]  text-white  hover:text-black"
            autoFocus
            onClick={() => {
              removeTags();
            }}
          ></Button>
        </div>
      </Dialog>
    </div>
  );
}
