import React, { useState, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Header from "../../components/Header";
import profile from "../../imgs/profile-cropped.svg";
import { useNavigate } from "react-router-dom";
import "../../styling/Custom.css";

export default function SkipService() {
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const uploadSkip = () => {
    navigate("/skiptrace-upload");
  };

  return (
    <div className="card">
      <Toast ref={toast} />
      <Header
        username={localStorage.getItem("firstName")}
        profileLink="/options"
        profileImage={profile}
        onlyProfile={true}
      />
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        className="justfy-content-center height-0"
      >
        <TabPanel header="" disabled>
          <div className="mx-auto">
            <h1 className="text-[50px] text-black font-medium"> Why us?</h1>
            <p className="m-1 text-black text-[20px]">
              We believe skiptracing companies charge a lot more than they
              should. With our state-of-the-art algorithms and techniques, we
              promise to clean your data from any possible duplicates and get
              you the best deals on skiptracing.
            </p>
            <div className="flex flex-col items-center justify-center text-center mt-6">
              <Button
                className="rounded-full bg-[#E6AC6B] text-center text-black font-semibold w-1/4 h-[50px] flex items-center justify-center p-2"
                onClick={() => setActiveIndex(1)}
              >
                Try it now!
              </Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="">
          <div className="mx-auto">
            <h1 className="text-[50px] text-black font-medium">
              {" "}
              How does it work under the hood?
            </h1>
            <p className="m-1 text-black text-[20px]">
              Your database of records is stored and safe in your RES data
              storage. When you upload a new list and want to skip it, we only
              do that with records that have not been skipped by you in the most
              recent window of time.Each record processed will be charged at a
              rate of <b>$0.09</b>.
            </p>
            <div className="flex flex-col items-center justify-center text-center mt-6">
              <Button
                className="rounded-full bg-[#00C4CC] text-center text-black font-semibold w-1/4 h-[50px] flex items-center justify-center p-2"
                onClick={() => uploadSkip()}
              >
                Go To Upload
              </Button>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
