import React, { useState, useRef } from "react";
import RegistrationCard from "../../components/RegistrationCard";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Authorization/AuthContext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { TabView, TabPanel } from "primereact/tabview";
import { MultiSelect } from "primereact/multiselect";
import {
  signIn,
  register,
  createCampaign,
  updateClientDetails,
} from "../../utils/api";
import { Toast } from "primereact/toast";
import "../../styling/Custom.css";

export default function RegisterScreen() {
  const toast = useRef(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [choice, setChoice] = useState([]);
  const [department, setDepartment] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonPhone, setContactPersonPhone] = useState("");

  const joinDate = new Date().toISOString().split("T")[0];

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const menu = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const departments = [
    { name: "Cold Calling", code: "CC" },
    { name: "Texting", code: "Texting" },
  ];
  const items = [
    {
      label: "Sign In",
      icon: "pi pi-sign-in",
      command: () => {
        handleClick();
      },
    },
  ];
  const handleClick = () => {
    navigate("/signin");
  };
  const handleSignIn = () => {
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      companyName.trim() === "" ||
      phoneNumber.trim() === ""
      // contactPersonName.trim() === "" ||
      // contactPersonPhone.trim() === ""
    ) {
      setShowError(true);
      setErrorMessage("All fields are required");
      return;
    } else if (password !== confirmPassword) {
      setShowError(true);
      setErrorMessage("Passwords do not match");
      setPassword("");
      setConfirmPassword("");
      return;
    } else if (!email.includes("@") || !email.includes(".")) {
      setShowError(true);
      setErrorMessage("Invalid email address");
      setEmail("");
      return;
    }
    handleSetUp();
  };
  const preferences = async (setUp) => {
    if (setUp) {
      navigate("/setUp");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("client_id", localStorage.getItem("client_id"));
      formData.append(
        "campaign_name",
        localStorage.getItem("firstName") + localStorage.getItem("lastName")
      );
      formData.append("campaign_market", "campaing_market");
      formData.append("campaign_requirements", "campaign_requirements");
      const response = await createCampaign(formData);
      console.log("campaign added Successfully", response.data);
      const updateForm = new FormData();
      updateForm.append("client_id", localStorage.getItem("client_id"));
      updateForm.append("department", department);
      updateForm.append("skiptrace_software", "undefined");
      updateForm.append("keep_last_skipped", true);
      updateForm.append("merge_tags", true);
      updateForm.append("keep_more_complete", false);
      const response1 = await updateClientDetails(updateForm);
      if (response.success) {
        console.log("registration done", response1.data);
      } else {
        console.error("Upload failed:", response1.data);
      }
      navigate("/options");
      setLoading(false);
    }
  };
  const handleDep = (choice) => {
    if (choice.length > 1) {
      setDepartment("CC-Texting");
    } else {
      setDepartment(choice[0].code);
    }
  };
  const handleSetUp = async () => {
    const formData = {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      email,
      password,
      phone: phoneNumber,
      contact_person_name: contactPersonName,
      contact_person_phone: contactPersonPhone,
      skiptrace_software: "Undefined Software",
      join_date: joinDate,
      keep_last_skipped: true,
      merge_tags: true,
      keep_more_complete: false,
      department: "CC",
    };

    const registerResponse = await register(formData);
    console.log(registerResponse);
    setLoading(true);
    if (registerResponse.success) {
      const loginResponse = await signIn(email, password);
      if (loginResponse.success) {
        const data = loginResponse.data;
        login(
          data.client.first_name,
          data.client.last_name,
          data.client.client_id,
          data.role,
          data.client.email,
          data.client.department
        );
        setLoading(false);
        setVisible(true);
      } else {
        setShowError(true);
        setErrorMessage("unexpected error please try again!");
      }
    } else {
      setLoading(false);
      setShowError(true);
      setErrorMessage(registerResponse.data);
    }
  };

  const hideDialog = () => {
    setShowError(false);
    setEmail("");
    setPassword("");
  };

  const inputFields = [
    {
      value: firstName,
      onChange: (e) => setFirstName(e.target.value),
      placeholder: "First Name",
    },
    {
      value: lastName,
      onChange: (e) => setLastName(e.target.value),
      placeholder: "Last Name",
    },
    {
      value: companyName,
      onChange: (e) => setCompanyName(e.target.value),
      placeholder: "Company Name",
    },
    {
      value: email,
      onChange: (e) => setEmail(e.target.value),
      placeholder: "Email Address",
    },
    {
      value: phoneNumber,
      onChange: (e) => setPhoneNumber(e.target.value),
      placeholder: "Phone Number",
    },
    {
      value: contactPersonName,
      onChange: (e) => setContactPersonName(e.target.value),
      placeholder: "Contact Person Name",
    },
    {
      value: contactPersonPhone,
      onChange: (e) => setContactPersonPhone(e.target.value),
      placeholder: "Contact Person Phone",
    },
  ];

  const passwordFields = [
    {
      value: password,
      onChange: (e) => setPassword(e.target.value),
      placeholder: "Password",
      feedback: true,
    },
    {
      value: confirmPassword,
      onChange: (e) => setConfirmPassword(e.target.value),
      placeholder: "Confirm Password",
      feedback: false,
    },
  ];

  return (
    <div>
      <Toast ref={toast} />
      {!loading ? (
        <div>
          <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 w-full">
            <div className="relative flex items-center justify-between w-full px-3 py-2">
              <img
                src={
                  "https://res-data-space.fra1.cdn.digitaloceanspaces.com/icons/res-logo-removebg.png"
                }
                alt="RESVA Data Solutions"
                className="w-[70px] "
              />

              <button
                className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
                type="button"
                onClick={(event) => menu.current.toggle(event)}
              >
                <i className="pi pi-bars text-black"></i>{" "}
                {/* Change 'text-red-500' to your desired color */}
              </button>
            </div>

            <div className="lg:flex lg:flex-grow items-center justify-end hidden w-full lg:w-auto px-3 py-2">
              <Menu model={items} popup ref={menu} />
            </div>
          </nav>
          <RegistrationCard
            title={"Register"}
            buttonText={"Go"}
            inputFields={inputFields}
            passwordFields={passwordFields}
            onSignIn={handleSignIn}
          />
          <Dialog
            header="Error"
            visible={showError}
            style={{ width: "50vw" }}
            onHide={hideDialog}
            dismissableMask
          >
            <p>{errorMessage}</p>
          </Dialog>
          <Dialog
            visible={visible}
            modal
            className="flex flex-column justify-centerflex flex-col rounded-3xl  items-center justify-center sm:w-1/3 md:w-1/2 lg:w-1/2 max-w-lg p-3 bg-white shadow-sm shadow-black dialog "
            onHide={() => {
              if (!visible) return;
              setVisible(false);
            }}
            content={({ hide }) => (
              <div>
                <img
                  src={
                    "https://res-data-space.fra1.cdn.digitaloceanspaces.com/icons/res-logo-removebg.png"
                  }
                  alt="logo"
                  className="w-[50px] flex justify-center m-[70] p-[40]"
                />
                {activeIndex == 0 && (
                  <h2 className="text-center text-[22px]">
                    Welcome to RES Data Services
                  </h2>
                )}
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  <TabPanel header="" disabled>
                    <p className="py-4">
                      We’re excited to have you on board with the ultimate data
                      management solution designed just for you.Please select
                      the department where you’ll be sending most of your lists.
                    </p>
                    <div className="card flex justify-center p-2 bg-white shadow-lg rounded-md">
                      <MultiSelect
                        value={choice}
                        onChange={(e) => setChoice(e.value)}
                        options={departments}
                        optionLabel="name"
                        placeholder="Select department"
                        maxSelectedLabels={2}
                        className="w-full md:w-80 border border-gray-300 bg-gray-50 text-gray-700 p-2 rounded-md focus:border-blue-500"
                      />
                    </div>
                    <p className="py-4">
                      Rest assured, you have the flexibility to customize the
                      department with each list upload if needed.
                    </p>

                    <div className="flex pt-4 justify-content-end">
                      <Button
                        label="Next"
                        icon="pi pi-arrow-right"
                        iconPos="right"
                        onClick={() => {
                          if (choice.length === 0) {
                            toast.current.show({
                              severity: "warn",
                              summary: "Warning",
                              detail: `Please Select a department`,
                              life: 5000,
                            });
                          } else {
                            handleDep(choice);
                            setActiveIndex(1);
                          }
                        }}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel header="" disabled>
                    <div className="w-full mx-auto flex justify-center">
                      <p className="text-center">
                        {" "}
                        The next steps are a{" "}
                        <span className="text-[#00C4CC] font-bold">
                          {" "}
                          one-time
                        </span>{" "}
                        <span className="font-medium">setup</span> to know your
                        preferences, it will be applied every time you upload a
                        list.If skip it now we will set it to default
                        preferences.
                      </p>
                    </div>
                    <div className="flex align-items-center gap-2">
                      <Button
                        label="Skip"
                        onClick={() => {
                          preferences(false);
                        }}
                        text
                        className="p-3 w-full text-[#009CCF] border-1 border-white-alpha-30 hover:text-[#8de0ff]"
                      ></Button>
                      <Button
                        label="Set-up Now"
                        onClick={() => {
                          preferences(true);
                        }}
                        text
                        className="p-3 w-full text-[#009CCF] border-1 border-white-alpha-30 hover:text-[#8de0ff]"
                      ></Button>
                    </div>
                    <div className="flex pt-4 justify-content-start">
                      <Button
                        label="Back"
                        severity="secondary"
                        icon="pi pi-arrow-left"
                        onClick={() => {
                          setActiveIndex(0);
                        }}
                      />
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            )}
          ></Dialog>
        </div>
      ) : (
        <Loading message="we're setting up your account" />
      )}
    </div>
  );
}
