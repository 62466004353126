import React, { useState, useRef, useEffect } from "react";
import RegistrationCard from "../components/RegistrationCard";
import { Dialog } from "primereact/dialog";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useAuth } from "../Authorization/AuthContext";
import { Menu } from "primereact/menu";
import { signIn } from "../utils/api";

export default function SignInScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const menu = useRef(null);

  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "";

  // Check if there's a logout message in localStorage
  const message = localStorage.getItem("logoutMessage");
  //notification when upload is successful

  useEffect(() => {
    // Check if there's a logout message in localStorage
    console.log(message);
    if (message === "Your session has expired. Please log in again.") {
      setWarning(true);
    }
  }, []);

  const items = [
    {
      label: "Register",
      icon: "pi pi-user-plus",
      command: () => {
        handleClickRegister();
      },
    },
  ];
  const handleClickRegister = () => {
    navigate("/register");
  };
  const inputFields = [
    {
      value: email,
      onChange: (e) => {
        setEmail(
          e.target.value.charAt(0).toLowerCase() + e.target.value.slice(1)
        );
      },
      placeholder: "Email Address",
    },
  ];
  const passwordFields = [
    {
      value: password,
      onChange: (e) => setPassword(e.target.value),
      placeholder: "Password",
      feedback: false,
    },
  ];

  const handleSignIn = async () => {
    setLoading(true);
    const response = await signIn(email, password);
    setLoading(false);
    console.log(response);
    if (response.success) {
      const data = response.data;
      console.log(response);
      login(
        data.client.first_name,
        data.client.last_name,
        data.client.client_id,
        data.role,
        data.client.email,
        data.client.department
      );
      console.log("User Role:", data.role);
      if (from !== "") {
        navigate(from);
      } else if (data.role === "user") {
        navigate("/options");
      } else if (data.role === "worker") {
        navigate("/data-options");
      }
    } else {
      setShowError(true);
    }
  };

  const hideDialog = () => {
    setShowError(false);
    setEmail("");
    setPassword("");
  };

  return (
    <>
      {!loading ? (
        <>
          <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 w-full">
            <div className="relative flex items-center justify-between w-full px-3 py-2">
              <img
                src={
                  "https://res-data-space.fra1.cdn.digitaloceanspaces.com/icons/res-logo-removebg.png"
                }
                alt="RESVA Data Solutions"
                className="w-[70px] "
              />

              <button
                className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
                type="button"
                onClick={(event) => menu.current.toggle(event)}
              >
                <i className="pi pi-bars text-black"></i>{" "}
                {/* Change 'text-red-500' to your desired color */}
              </button>
            </div>

            <div className="lg:flex lg:flex-grow items-center justify-end hidden w-full lg:w-auto px-3 py-2">
              <Menu model={items} popup ref={menu} />
            </div>
          </nav>
          <div className=" flex flex-col gap-3 mx-auto ">
            <RegistrationCard
              title={"Sign In"}
              buttonText={"Go"}
              inputFields={inputFields}
              passwordFields={passwordFields}
              destination={"/options"}
              onSignIn={handleSignIn}
            />
            <Dialog
              header="Error"
              visible={showError}
              style={{ width: "50vw" }}
              onHide={hideDialog}
            >
              <p>Incorrect email or password. Please try again.</p>
            </Dialog>
            <Dialog
              header="Session Timout"
              visible={warning}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!warning) return;
                setWarning(false);
                localStorage.removeItem("logoutMessage");
              }}
            >
              <p>
                It looks like you've been inactive for a while, so we've logged
                you out to keep your account secure. Please sign in again to
                continue
              </p>
            </Dialog>
          </div>
        </>
      ) : (
        <Loading message="we're signing you in" />
      )}
    </>
  );
}
