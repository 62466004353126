import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PrivateRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, role } = useAuth();
  const location = useLocation();

  if (isAuthenticated && requiredRole.includes(role)) {
    return children;
  } else if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} />;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

export default PrivateRoute;
