import React, { useState, useEffect, useRef, useCallback } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  fetchTags,
  fetchCampaign,
  fetchClientTags,
  addTagsToFile,
  uploadListToSkip,
} from "../../utils/api";
import success from "../../imgs/success-cropped.svg";
import profile from "../../imgs/profile-cropped.svg";
import Card from "../../components/OptionCard";
import Header from "../../components/Header";
import chat from "../../imgs/SkiptraceChat-cropped.svg";
import done from "../../imgs/SkiptraceFinal-cropped.svg";
import "../../styling/Multistep.css";

export default function MultiStepSkipTracing() {
  const stepperRef = useRef(null);
  const warn = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [titleUpload, setTitleUpload] = useState(
    "Upload any list in csv or xlsx format"
  );
  const [selections, setSelections] = useState({});
  const [bgColors, setBgColors] = useState({});
  const client_id = localStorage.getItem("client_id");
  const client_name = localStorage.getItem("firstName");
  const [depOptions, setDepOptions] = useState({});
  const [possibleSource, setPossibleSource] = useState("");
  const [tagsList, setTagsList] = useState(false);
  const [cold_calling, setColdCalling] = useState(() => {
    const dep = localStorage.getItem("department");
    if (dep === "CC" || dep === "CC-Texting") {
      return true;
    } else {
      return false;
    }
  });
  const [texting, setTexting] = useState(() => {
    const dep = localStorage.getItem("department");
    if (dep === "Texting" || dep === "CC-Texting") {
      return true;
    } else {
      return false;
    }
  });
  const [description, setDescription] = useState("");
  const [source, setSource] = useState("Other");
  const [list_pull_date, setListPullDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [current_version, setCurrentVersion] = useState(1);
  const [upload_date, setUploadDate] = useState("");
  const [file_size, setFileSize] = useState(0.0);
  const [uploaded_file, setUploadedFile] = useState(null);
  const [file_type, setFileType] = useState("CSV");
  const [original_name, setOriginalName] = useState("");
  const [activeIdx, setActiveIdx] = useState(0);
  const [progress, setProgress] = useState(0);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const interval = useRef(null);
  const loc = location.state;
  if (loc) {
    const selectedFile = location.state.selectedFile;
    const data = location.state.data;
    console.log(selectedFile);
    console.log(data);
  }
  //total steps
  const totalIdx = 6;
  //list sources for dropDown menu
  const sources = [
    { name: "Other" },
    { name: "80/20" },
    { name: "Audantic" },
    { name: "Batch Leads" },
    { name: "Kind Skip" },
    { name: "Skip Force" },
    { name: "County Records" },
    { name: "Prop Stream" },
  ];
  //get all tags in the system
  const { data: globalTags } = useQuery("fetchTags", fetchTags, {
    onSuccess: () => {
      console.log("tags are here");
    },

    cacheTime: 1000 * 60 * 30,
  });
  //fetch campaign id
  const { data: campaigns } = useQuery(
    ["fetchCampaign", client_id],
    () => fetchCampaign(client_id),
    {
      onSuccess: () => {
        console.log("camapaings are here");
      },
      cacheTime: 1000 * 60 * 30,
    }
  );
  //fetch client tags
  const { data: clientTags } = useQuery(
    ["fetchClientTags", client_id],
    () => fetchClientTags(client_id),
    {
      onSuccess: () => {
        console.log("client tags are here");
      },
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
    }
  );

  //Warning in case clicking next without entering required data
  const showWarn = () => {
    warn.current.show({
      severity: "warn",
      summary: "Warning",
      detail: " Please Enter Required Data To Proceed",
      life: 3000,
    });
  };
  //notification when unsuccessful
  const showErorr = () => {
    toast.error(
      <span>
        There was an issue uploading the file. Please try again later. If the
        problem continues,
        <a
          href="/contact-us"
          className="underline text-blue-600 hover:text-blue-800"
        >
          contact us
        </a>
        .
      </span>,
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };
  // Handle clicking on next
  const handleNextClick = (current_step, state, ref) => {
    console.log(current_step);
    if (state) {
      ref.current.nextCallback();
      setActiveIdx((prev) => prev + 1);
    } else {
      showWarn();
    }
  };
  //Handle Upload file
  const handleFileUpload = (file) => {
    // TODO: adjust file types + excel files because they cause an error
    const validTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!validTypes.includes(file.type)) {
      warn.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "File type not supported. Please upload a CSV or Excel file.",
        life: 3000,
      });
      return;
    }
    if (file.type === "text/csv") {
      setFileType("csv");
    } else if (file.type === "application/vnd.ms-excel") {
      setFileType("xls");
    } else {
      setFileType("xlsx");
    }
    setUploadedFile(file);
    console.log(file);
    const nameTrimmed = file.name.split(".").slice(0, -1).join(".");
    setOriginalName(nameTrimmed);
    setFileSize(file.size);
    setCurrentVersion(1);
    setUploadDate(new Date().toISOString().split("T")[0]);
    setTitleUpload("Your file is uploaded successfully");
    setShowSuccessMessage(true);
  };
  //handle close button of success message
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  //Handle changes in source and skip source card
  const handleDepChange = (card, choice) => {
    console.log(card, choice);
    if (card === "Source") {
      console.log("Setting source here");
      if (choice === "Yes") {
        setSource(possibleSource);
      }
      if (choice !== "No" && choice !== "Yes") {
        setSource(choice);
      }
    }
  };
  //handle date change in list date and skip date
  const handleCalendarInput = (card, choice) => {
    if (card === "List Pull Date") {
      setListPullDate(choice);
    }
  };
  //handle multiple department selection or tags
  useEffect(() => {
    console.log(depOptions);
    console.log(selections);
    if (Object.keys(selections).length === 0) {
      setTagsList(false);
    }
    if (Object.keys(depOptions).length > 0) {
      for (const option in depOptions) {
        if (depOptions[option]) {
          console.log("Setting", option, "to true");
          switch (option) {
            case "Cold Calling":
              setColdCalling(true);

              break;
            case "Texting":
              setTexting(true);

              break;
            default:
              break;
          }
        }
      }
    }
    if (Object.keys(selections).length > 0) {
      setTagsList(true);
    }
  }, [depOptions, selections]);
  //handle file upload to send the file to the worker
  const uploadSkipData = useCallback(async () => {
    const formData = new FormData();
    formData.append("client_id", client_id);
    formData.append("cold_calling", cold_calling);
    formData.append("texting", texting);
    formData.append("source", possibleSource ? possibleSource : source);
    formData.append("description", description);
    formData.append("list_pull_date", list_pull_date);
    formData.append("upload_date", upload_date);
    formData.append("uploaded_file", uploaded_file);
    formData.append("original_name", original_name);
    formData.append("campaign_id", campaigns[0].campaign_id);
    formData.append("file_size", file_size);
    const fileName = `${client_name}${cold_calling ? "-CC" : ""}${
      texting ? "-Texting" : ""
    }-${source}-${list_pull_date}-${original_name}-v${current_version}.${file_type}`;
    formData.append("file_name", fileName);
    let _val = progress;
    console.log("uploading");
    const response = await uploadListToSkip(
      formData,
      _val,
      setProgress,
      interval
    );
    console.log("done file");
    const formDataFile = new FormData();
    formDataFile.append("client_id", client_id);
    formDataFile.append("file_id", response.data.file_id);
    formDataFile.append("tags", Object.values(selections).join(","));
    setProgress(98);
    clearInterval(interval.current);
    interval.current = null;

    const response2 = await addTagsToFile(formDataFile);

    console.log(response2.data);
    console.log("done tags");
    clearInterval(interval.current);
    interval.current = null;
    if (response.success && response2.success) {
      setUploadSuccessful(true);
    } else {
      showErorr();
      navigate("/options");
    }
  }, [
    client_id,
    cold_calling,
    texting,
    source,
    description,
    list_pull_date,
    upload_date,
    uploaded_file,
    original_name,
    file_size,
    campaigns,
    client_name,
    current_version,
    file_type,
    selections,
  ]);
  useEffect(() => {
    // const uploadSkipData = async () => {
    //   const formData = new FormData();
    //   formData.append("client_id", client_id);
    //   formData.append("cold_calling", cold_calling);
    //   formData.append("texting", texting);
    //   formData.append("source", source);
    //   formData.append("description", description);
    //   formData.append("list_pull_date", list_pull_date);
    //   formData.append("upload_date", upload_date);
    //   formData.append("uploaded_file", uploaded_file);
    //   formData.append("original_name", original_name);
    //   formData.append("campaign_id", campaigns[0].campaign_id);
    //   formData.append("file_size", file_size);
    //   const fileName = `${client_name}${cold_calling ? "-CC" : ""}${
    //     texting ? "-Texting" : ""
    //   }-${source}-${list_pull_date}-${original_name}-v${current_version}.${file_type}`;
    //   formData.append("file_name", fileName);
    //   let _val = progress;
    //   console.log("uploading");
    //   const response = await uploadListToSkip(
    //     formData,
    //     _val,
    //     setProgress,
    //     interval
    //   );
    //   console.log("done file");
    //   const formDataFile = new FormData();
    //   formDataFile.append("client_id", client_id);
    //   formDataFile.append("file_id", response.data.file_id);
    //   formDataFile.append("tags", Object.values(selections).join(","));
    //   setProgress(98);
    //   clearInterval(interval.current);
    //   interval.current = null;

    //   const response2 = await addTagsToFile(formDataFile);

    //   console.log(response2.data);
    //   console.log("done tags");
    //   clearInterval(interval.current);
    //   interval.current = null;
    //   if (response.success && response2.success) {
    //     setUploadSuccessful(true);
    //   } else {
    //     showErorr();
    //     navigate("/options");
    //   }
    // };
    if (readyForUpload) {
      uploadSkipData();
    }
  }, [readyForUpload]);

  return (
    <div>
      <Header
        username={client_name}
        profileLink="/options"
        profileImage={profile}
        upload={true}
      />
      {showSuccessMessage && (
        <Dialog
          header="Success"
          visible={showSuccessMessage}
          onHide={closeSuccessMessage}
        >
          <div className="flex flex-col justify-center">
            <div className="alert alert-success">
              File {uploaded_file.name} uploaded successfully!
            </div>
          </div>
        </Dialog>
      )}

      <div className="flex flex-col min-h-svh gap-12">
        <Stepper ref={stepperRef} linear style={{ flexBasis: "50rem" }}>
          <StepperPanel header="Upload File">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={totalIdx}
                  title={titleUpload}
                  upload={true}
                  image={success}
                  uploadedFile={uploaded_file}
                  onFileUpload={handleFileUpload}
                />
              </div>
            </div>
            <Toast ref={warn} />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("file", uploaded_file, stepperRef);
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Department">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={totalIdx}
                  title={
                    "For which department do you intend to send this list?"
                  }
                  choices={["Cold Calling", "Texting"]}
                  onDescriptionChange={setDescription}
                  all={true}
                  selections={setDepOptions}
                  setBgColors={setBgColors}
                  bgColors={bgColors}
                />
              </div>
            </div>
            <Toast ref={warn} />
            <div className="flex pt-4 justify-content-between">
              <Button
                disabled
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick("department", true, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="List Source">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={2}
                  dropMenu={true}
                  list={true}
                  totalIdx={totalIdx}
                  title={
                    possibleSource ? (
                      <span>
                        This looks like a list from <b>{possibleSource}</b>, is
                        that correct?
                      </span>
                    ) : (
                      ""
                    )
                  }
                  choices={possibleSource ? ["Yes", "No"] : []}
                  sources={sources}
                  onListSource={handleDepChange}
                  visible={!possibleSource}
                  setBgColors={setBgColors}
                  bgColors={bgColors}
                  date={list_pull_date}
                  onCalendarInput={handleCalendarInput}
                />
              </div>
            </div>
            <Toast ref={warn} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() =>
                  handleNextClick("list_souce", source, stepperRef)
                }
              />
            </div>
          </StepperPanel>
          {/* <StepperPanel header="List Pull Date">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  pullDate={true}
                  totalIdx={totalIdx}
                  title={"When did you last pull that list?"}
                  calendar={true}
                  onCalendarInput={handleCalendarInput}
                />
              </div>
            </div>
            <Toast ref={warn} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() =>
                  handleNextClick("date", list_pull_date, stepperRef)
                }
              />
            </div>
          </StepperPanel> */}
          <StepperPanel header="List Tags">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={totalIdx}
                  title={
                    "Choose the tags you want to add to your list, if you have already chosen some presets they will be applied automatically"
                  }
                  tags={true}
                  clientTags={clientTags}
                  input={true}
                  vertical={true}
                  sources={globalTags}
                  selections={setSelections}
                  setBgColors={setBgColors}
                  bgColors={bgColors}
                />
              </div>
            </div>
            <Toast ref={warn} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("tags", true, stepperRef);
                  // if (tagsList) {
                  setReadyForUpload(true);
                  // }
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Final Step">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <div className=" mx-6 w-[590px]">
                  <h1 className="text-[25px] text-black text-center font-semibold">
                    Cool! Data is being fed to the system..
                  </h1>
                  <div className=" flex-auto flex justify-content-center align-items-center ">
                    {uploadSuccessful ? (
                      <img
                        src={done}
                        alt="chat"
                        className="w-2/4 m-4 justify-center"
                      />
                    ) : (
                      <ProgressBar
                        className="w-1/2 m-8 h-[60px]"
                        value={progress}
                      />
                    )}
                  </div>
                  {uploadSuccessful && (
                    <h1 className="text-[25px] text-black text-center font-semibold">
                      You will be notified when results are ready.
                    </h1>
                  )}
                </div>
                <Divider layout="vertical" />
                <div className=" mr-5">
                  <img
                    src={chat}
                    alt="chat"
                    className="w-full m-3 ml-4 h-[363px]"
                  />
                </div>
              </div>
            </div>
            <Toast ref={warn} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  if (uploadSuccessful) {
                    navigate("/options");
                  }
                }}
              />
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
}
