import React, { useEffect } from "react";
import { Button } from "primereact/button";
import numbers from "../../imgs/bar-chart_7.svg";
import mouse from "../../imgs/mouse-arrow-svgrepo-com.svg";
import { useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";
import Loading from "../../components/Loading";
import CountUp from "react-countup";
import { useQuery } from "react-query";
import { fetchDbSummary } from "../../utils/api";

export default function SummaryPage() {
  const navigate = useNavigate();
  const goToDatabase = () => {
    navigate("/database");
  };
  const fetchSummary = async () => {
    const response = await fetchDbSummary(localStorage.getItem("client_id"));
  };
  const {
    data: summary,
    isLoading: filesLoading,
    isFetching: filesFetching,
  } = useQuery(["fetchDbSummary", localStorage.getItem("client_id")], () =>
    fetchDbSummary(localStorage.getItem("client_id"))
  );
  return (
    <div>
      {!filesLoading ? (
        <>
          <h1 className="text-6xl font-extralight text-center mt-6">
            You have{" "}
            <CountUp start={0} end={summary.total_files} delay={0}>
              {({ countUpRef, start }) => (
                <span
                  ref={countUpRef}
                  onLoad={start}
                  className="font-semibold"
                />
              )}
            </CountUp>{" "}
            lists and{" "}
            <span className="font-bold">{summary.total_unique_records}</span>{" "}
            unique records in your database{" "}
          </h1>

          <div className="flex flex-col sm:flex-row mt-6 mx-4 items-start">
            <div className="flex-1 sm:w-1/2 flex items-center justify-center p-4">
              <div className="text-center w-full">
                <h2 className="h-[72px] text-3xl font-semibold mb-6 flex flex-col  lg:flex-row items-center justify-center   lg:space-x-2">
                  Breakdown Of Your Numbers
                  <img
                    src={numbers}
                    alt="Left Icon"
                    className="w-1 h-6 m-2 mb-3 "
                  />
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center text-sm md:text-md lg:text-lg xl:text-lg font-medium overflow-wrap break-words text-center m-1 xl:min-h-[0px] md:min-h-[85px] min-w-[96px]">
                      Total records uploaded
                    </div>
                    <div className=" bg-[#a6d8f5] flex items-center justify-center shadow-custom font-bold text-900 rounded-xl  text-center text-[18px] w-full h-[56px] px-6">
                      {summary.total_records}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center text-sm md:text-md lg:text-lg xl:text-lg font-medium overflow-wrap break-words text-center m-1 xl:min-h-[0px] md:min-h-[85px] min-w-[96px]">
                      Records currently on dialer
                    </div>
                    <div className=" bg-[#fdb185] flex items-center justify-center shadow-custom font-bold text-900 rounded-xl  text-center text-[18px] w-full h-[56px] px-6 ">
                      {summary.total_records_uploadedToDialer}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center text-sm md:text-md lg:text-lg xl:text-lg font-medium overflow-wrap break-words text-center m-1 xl:min-h-[0px] md:min-h-[140px] min-w-[96px]">
                      Total records after duplicate removal
                    </div>
                    <div className=" bg-[#f57675] flex items-center justify-center shadow-custom font-bold text-900 rounded-xl  text-center text-[18px] w-full h-[56px] px-6 ">
                      {summary.total_unique_records}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center text-sm md:text-md lg:text-lg xl:text-lg font-medium overflow-wrap break-words text-center m-1 xl:min-h-[0px] md:min-h-[140px] min-w-[96px]">
                      Money you saved by using our deduplication service
                    </div>
                    <div className=" bg-[#75d58e] flex items-center justify-center shadow-custom font-bold text-900 rounded-xl  text-center text-[18px] w-full h-[56px] px-6">
                      ${summary.total_money_saved}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-px bg-gray-400 mx-[40px] lg:mx-[100px] md:mx-[80px] self-stretch"></div>
            <div className="block sm:hidden w-full bg-gray-400 h-px my-4"></div>

            <div className="flex-1 sm:w-1/2 flex items-center justify-center p-4">
              <div className="text-center w-full">
                <h2 className="h-[72px] text-3xl font-semibold mb-6 flex flex-col  lg:flex-row items-center justify-center   lg:space-x-2">
                  List Status Overview
                  <img src={mouse} alt="Filter Icon" className="w-1 h-5 m-2 " />
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-[20px]">
                  <div className="flex flex-col items-center">
                    <Tag
                      value="Uploaded To Dialer"
                      severity="success"
                      className="w-full h-[40%] text-sm md:text-md lg:text-lg xl:text-lg  p-2 px-6  "
                    />
                    <div className="flex items-center justify-center text-sm md:text-md lg:text-lg xl:text-lg font-medium overflow-wrap break-words text-center m-1 mt-2 xl:min-h-[0px] md:min-h-[100px] min-w-[96px]">
                      <span>
                        <b>{summary.status_counts["uploaded_to_dialer"]}</b>{" "}
                        lists successfully uploaded to the dialer
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <Tag
                      value="Pending Dialer Upload"
                      severity="warning"
                      className="w-full h-[40%] text-sm md:text-md lg:text-lg xl:text-lg  p-2 px-6"
                    />
                    <div className="flex items-center justify-center text-sm md:text-md lg:text-lg xl:text-lg font-medium overflow-wrap break-words text-center m-1 mt-2 xl:min-h-[0px] md:min-h-[100px] min-w-[96px]">
                      <span>
                        <b>{summary.status_counts["pending_dialer_upload"]}</b>{" "}
                        lists uploaded but pending dialer upload
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <Tag
                      value="Pending Cleaning"
                      severity="danger"
                      className="w-full h-[40%] text-sm md:text-md lg:text-lg xl:text-lg  p-2 px-6"
                    />
                    <div className="flex items-center justify-center text-sm md:text-md lg:text-lg xl:text-lg font-medium overflow-wrap break-words text-center m-1 mt-2 xl:min-h-[0px] md:min-h-[100px] min-w-[96px]">
                      <span>
                        <b>{summary.status_counts["pending_cleaning"]}</b> lists
                        uploaded but need further processing to be uploaded the
                        dialer
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <Tag
                      value="Pending Skip Tracing"
                      severity="info"
                      className="w-full h-[40%] text-sm md:text-md lg:text-lg xl:text-lg p-2 px-6"
                    />
                    <div className="flex items-center justify-center text-sm md:text-md lg:text-lg xl:text-lg font-medium overflow-wrap break-words text-center m-1 mt-2 xl:min-h-[0px] md:min-h-[100px] min-w-[96px]">
                      <span>
                        <b>{summary.status_counts["pending_skip_tracing"]}</b>{" "}
                        lists requested for skip tracing and the results are not
                        yet available
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center text-center mt-4 mb-4">
            <Button
              className="rounded-full bg-[#8de0ff] hover:bg-[#009ccf] text-center text-black font-semibold sm:w-1/2 lg:w-1/4 h-[50px] flex items-center justify-center px-4 py-2 text-lg md:text-xl lg:text-2xl"
              onClick={() => goToDatabase()}
            >
              View Database
            </Button>
          </div>
        </>
      ) : (
        <Loading message="we're retrieving your data" />
      )}
    </div>
  );
}
