import React, { useState, useRef } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { fetchClients, updateCampaign } from "../../utils/api";
import { useQuery } from "react-query";
import profile from "../../imgs/profile-cropped.svg";
import Header from "../../components/Header";
import WalkThroughCard from "../../components/WalkthroughCard";
import success from "../../imgs/success-cropped.svg";
import "../../styling/Multistep.css";

export default function CampaignEdit() {
  const stepperRef = useRef(null);
  const warning = useRef(null);
  const navigate = useNavigate();

  const [activeIdx, setActiveIdx] = useState();
  const [campaignChanged, setCampaignChanged] = useState(false);
  const [client, setClient] = useState("");
  const [clientDone, setClientDone] = useState(false);
  const [campaign, setCampaign] = useState("");
  const [campaignDone, setCampaignDone] = useState(false);
  const inputs = [
    {
      name: "Campaign Name",
      placeholder: "e.g. Jetters",
      type: "Text",
      key: "alphanum",
    },
  ];
  //get clients
  const { data: clients, isLoading: clientsLoading } = useQuery(
    "fetchClients",
    fetchClients,
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
    }
  );
  // update campaign name
  const updateName = async () => {
    const response = await updateCampaign(client.client_id, campaign);
    if (response.status === 201) {
      setCampaignChanged(true);
    }
  };
  //Warning in case clicking next without entering required data
  const showWarn = () => {
    warning.current.show({
      severity: "warn",
      summary: "Warning",
      detail: " Please Enter Required Data To Proceed",
      life: 3000,
    });
  };
  // Handle clicking on next
  const handleNextClick = (current_step, state, ref) => {
    if (state) {
      if (current_step === "campaign") {
        updateName();
        if (campaignChanged) {
          navigate("/data-options");
        }
      }
      ref.current.nextCallback();
      setActiveIdx((prev) => prev + 1);
    } else {
      showWarn();
    }
  };

  return (
    <div>
      <Header
        username={""}
        profileLink="/options"
        profileImage={profile}
        upload={true}
      />
      <div className="flex flex-col min-h-svh gap-12">
        <Stepper ref={stepperRef} linear style={{ flexBasis: "50rem" }}>
          <StepperPanel header="Client Details">
            <div>
              <WalkThroughCard
                dropDowns={true}
                menus={[
                  {
                    name: "Clients's name",
                    placeholder: "Enter Client Name",
                    option: "full_name",
                  },
                ]}
                drop_options={[clients]}
                disable={[false]}
                onSet={setClientDone}
                functions={[setClient]}
                fetching={[clientsLoading]}
                onChoice={setClientDone}
                activeIdx={activeIdx}
                totalIdx={2}
                question={
                  <span className="text-center font-semibold">
                    Select the client
                  </span>
                }
              />

              <Toast ref={warning} />
              <div className="flex pt-4 justify-content-end">
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => {
                    handleNextClick("client", clientDone, stepperRef);
                  }}
                />
              </div>
            </div>
          </StepperPanel>
          <StepperPanel header="Campaign name">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                {!campaignChanged ? (
                  <WalkThroughCard
                    description={true}
                    activeIdx={activeIdx}
                    totalIdx={2}
                    question=""
                    inputs={inputs}
                    functions={[setCampaign]}
                    onSet={setCampaignDone}
                  />
                ) : (
                  <WalkThroughCard
                    final={true}
                    question={
                      <span>
                        <b>{client.full_name} </b>'s campaign name has been
                        successfully updated.
                      </span>
                    }
                    destination={"/data-options"}
                    uploadSuccessful={true}
                    finalButton={"Go Home"}
                    img={success}
                  />
                )}
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("campaign", campaignDone, stepperRef);
                  // updateName();
                }}
              />
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
}
