import React, { useEffect } from "react";
import Header from "../../components/Header";
import profile from "../../imgs/profile-cropped.svg";
import Custsupport from "../../imgs/support.svg";
const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "24452375",
          formId: "9c089497-4d89-4459-aecd-ff5f078c852f",
          target: "#hubspotForm",
          onFormReady: (form) => {
            form.querySelector('input[name="user_name"]').value =
              localStorage.getItem("firstName") +
              " " +
              localStorage.getItem("lastName");
            form.querySelector('input[name="email"]').value =
              localStorage.getItem("email");
          },
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <Header
        username={localStorage.getItem("firstName")}
        profileLink="/options"
        profileImage={profile}
        options={false}
        onlyProfile={true}
        titleImage={Custsupport}
        title={"How Can We Help You?"}
      />

      <div className=" m-auto mt-4 justify-center items-center w-[50%]">
        <div id="hubspotForm"></div>
      </div>
    </div>
  );
};

export default HubSpotForm;
