import axios from "axios";
const api = axios.create({
  baseURL: "https://king-prawn-app-s6zzy.ondigitalocean.app/",
  //baseURL: "http://localhost:8000/",
});
export async function fetchTags() {
  try {
    const response = await api.get("file/tags");
    console.log("tags");
    return response.data;
  } catch (error) {
    // console.error("There was an error fetching the tags!", error);
  }
}

export async function fetchClients() {
  try {
    const response = await api.get("file/clients");
    response.data.forEach((person) => {
      person.full_name = person.first_name + " " + person.last_name;
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the clients!", error);
  }
}
export async function createCampaign(formData) {
  try {
    const response = await api.post("file/add-campaign", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { success: true, data: response };
  } catch (error) {
    console.error("There was an error changing campaign name!", error);
    return { success: false, data: error };
  }
}
export async function fetchCampaign(clientID) {
  try {
    const formDatacampaign = new FormData();
    formDatacampaign.append("client_id", clientID);
    const response = await api.post("file/client-campaigns", formDatacampaign, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("camps", response.data);
    sessionStorage.setItem("campaign_id", response.data[0].campaign_id);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the files!", error);
  }
}
export async function fetchDbSummary(clientID) {
  try {
    const formData = new FormData();
    formData.append("client_id", clientID);
    const response = await api.post("file/db-summary", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the files!", error);
  }
}
export async function updateCampaign(clientId, name) {
  const formDataTags = new FormData();
  formDataTags.append("client_id", clientId);
  formDataTags.append("new_campaign_name", name);
  try {
    const response = await api.post(
      "user/update-client-campaign",
      formDataTags,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("There was an error changing campaign name!", error);
  }
}
export async function pullList(formData) {
  try {
    const response = await api.post("file/pull-file-request", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { success: true, data: response };
  } catch (error) {
    console.error("There was an error uploading the criteria!", error);
    return { success: false, data: error };
  }
}
export async function dialerStats(formData) {
  try {
    const response = await api.post("file/upload-dialer-stats", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("There was an error uploading the criteria!", error);
  }
}
export async function fileDetails(formData) {
  try {
    const response = await api.post("user/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching file details!", error);
  }
}
export async function signIn(email, password) {
  try {
    const response = await api.post("user/login", {
      email: email,
      password: password,
    });
    console.log(response);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: "" };
  }
}
export async function register(formData) {
  try {
    const response = await api.post("/user/register", formData);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.error };
  }
}
export async function updatePassword(formData) {
  try {
    const response = await api.post("/user/change-password", formData);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.error };
  }
}
export async function updateClientDetails(formData) {
  try {
    const response = await api.post("user/update-client", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error };
  }
}
export async function addClientTags(payload) {
  try {
    const response = await api.post("file/add-client-tag", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error };
  }
}
export async function standardize(
  client_id,
  file,
  setDataColumns,
  setOptions,
  setSelectedValue,
  setAlreadyMapped,
  setEditMapping,
  setPossibleSource
) {
  try {
    const formData = new FormData();
    formData.append("client_id", client_id);
    formData.append("uploaded_file", file);
    const response = await api.post("file/standardize", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("here", response.data);
    setDataColumns(response.data.preview);
    response.data.list_columns.push("None");
    const entries = Object.entries(response.data.mappings);
    const updatedValues = entries.map(([key, value]) => value);
    //dropDown Menu Options
    setOptions(response.data.list_columns);
    if (setPossibleSource) {
      if (response.data.possible_source === "Undetermined") {
        setPossibleSource(false);
      } else {
        setPossibleSource(response.data.possible_source);
      }
    }
    //save dropdown menu ooptions in local storage
    sessionStorage.setItem(
      "savedOptions",
      JSON.stringify(response.data.list_columns)
    );
    // Set the state with the updated values
    setSelectedValue(updatedValues);
    setAlreadyMapped(response.data.previous_mapping);
    setEditMapping(response.data.previous_mapping);
    return response.data.mappings;
  } catch (error) {
    console.error("error in mapping", error);
  }
}
export async function fetchWorkerDb() {
  try {
    const formData = new FormData();
    formData.append("worker_id", localStorage.getItem("client_id"));
    const response = await api.post("file/database-management", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response);
    response.data.forEach((person) => {
      person.full_name =
        person.client.first_name + " " + person.client.last_name;
    });

    function groupFilesByClient(files) {
      const grouped = files.reduce((acc, file) => {
        // Group by client_id
        if (!acc[file.client_id]) {
          acc[file.client_id] = {
            key: `client_${file.client_id}`,
            label: file.full_name,
            data: {
              name: file.full_name,
              file: "Files",
              fileCount: 0,
            },
            children: [],
          };
        }

        // Check if there's already a group for the file's status within the client
        let statusGroup = acc[file.client_id].children.find(
          (child) => child.data.status === file.status
        );

        // If no status group exists, create one
        if (!statusGroup) {
          statusGroup = {
            key: `status_${file.status}_${file.client_id}`,
            label: `Status: ${file.status}`,
            data: {
              status: file.status,
              fileCount: 0,
            },
            children: [],
          };
          acc[file.client_id].children.push(statusGroup);
        }
        statusGroup.data.fileCount += 1;
        // Push the file into the correct status group
        statusGroup.children.push({
          key: `file_${file.file_id}`,
          label: file.file_name,
          data: {
            file:
              file.operations && file.operations.length > 0
                ? file.operations[0].file_name
                : file.file_name,
            detail:
              file.operations && file.operations.length > 0
                ? file.operations[0].download_link
                : "",
            file_id: file.file_id,
            client_id: file.client_id,
          },
        });
        // Increment the file count for the client
        acc[file.client_id].data.fileCount += 1;
        return acc;
      }, {});

      // Convert the grouped object to an array
      return Object.values(grouped).map((client, clientIndex) => ({
        key: String(clientIndex),
        data: client.data,
        children: client.children.map((statusGroup, statusIndex) => ({
          key: `${clientIndex}-${statusIndex}`,
          data: statusGroup.data,
          children: statusGroup.children.map((file, fileIndex) => ({
            key: `${clientIndex}-${statusIndex}-${fileIndex}`,
            data: file.data,
          })),
        })),
      }));
    }

    const groupedFiles = groupFilesByClient(response.data);
    return groupedFiles;
  } catch (error) {
    console.error("There was an error fetching the clients!", error);
  }
}
export async function fetchFiveFiles(formData) {
  const response = await api.post("user/files", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}
export async function fetchClientFiles(client) {
  try {
    const formData = new FormData();
    formData.append("client_id", client.client_id);
    const response = await api.post("file/client-files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "There was an error fetching the file for this client!",
      error
    );
  }
}
export async function fetchAllFiles(client) {
  try {
    const formData = new FormData();
    formData.append("client_id", client.client_id);
    const response = await api.post("user/all-files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "There was an error fetching the file for this client!",
      error
    );
  }
}
export async function fetchFilesStats(client) {
  try {
    const formData = new FormData();
    formData.append("client_id", client.client_id);
    const response = await api.post("user/not-uploaded", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "There was an error fetching the file for this client!",
      error
    );
  }
}
export async function fetchClientTags(clientId) {
  const formDataTags = new FormData();
  formDataTags.append("client_id", clientId);
  try {
    const response = await api.post("file/client-tags", formDataTags, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the client Tags!", error);
  }
}
export async function fetchBadFiles(client) {
  try {
    const FormDataFile = new FormData();
    FormDataFile.append("client_id", client.client_id);

    const response = await api.post("file/files-not-ready", FormDataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the files!", error);
  }
}
export async function fetchSkipFiles(client) {
  try {
    const FormDataFile = new FormData();
    FormDataFile.append("client_id", client.client_id);

    const response = await api.post("file/skip-tracing-files", FormDataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the files!", error);
  }
}

export async function fetchFileTags(fileId) {
  try {
    const FormDataFile = new FormData();
    FormDataFile.append("file_id", fileId);
    const response = await api.post("file/file-tags", FormDataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("tags", response.data.tag_names);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the files!", error);
  }
}
export async function fileCreate(formData, _val, interval, setProgress) {
  try {
    const response = await api.post("file/file-create/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      //simulate file upload
      onUploadProgress: function (progressEvent) {
        const total = progressEvent.total;
        const uploaded = progressEvent.loaded;
        _val = Math.round((uploaded * 100) / total);
        if (_val >= 96) {
          _val = 96;
          clearInterval(interval.current);
          interval.current = null;
        }
        setProgress(_val);
      },
    });
    return response.data;
  } catch (error) {
    console.error("There was an error uploading file!", error);
  }
}
export async function uploadRequestedList(
  formData,
  _val,
  setProgress,
  setUploadSuccessful
) {
  try {
    const response = await api.post("file/upload-requested-file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      //simulate file upload
      onUploadProgress: function (progressEvent) {
        const total = progressEvent.total;
        const uploaded = progressEvent.loaded;
        _val = Math.round((uploaded * 100) / total);
        setProgress(_val);
      },
    });
    setUploadSuccessful(true);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("There was an error uploading file!", error);
    return { success: false, data: error };
  }
}
export async function uploadListToSkip(formData, _val, setProgress, interval) {
  try {
    const response = await api.post("file/skiptrace", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      //simulate file upload
      onUploadProgress: function (progressEvent) {
        const total = progressEvent.total;
        const uploaded = progressEvent.loaded;
        _val = Math.round((uploaded * 100) / total);
        if (_val >= 96) {
          _val = 96;
          clearInterval(interval.current);
          interval.current = null;
        }
        setProgress(_val);
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("There was an error uploading file!", error);
    return { success: false, data: error };
  }
}
export async function addTagsToFile(formData) {
  try {
    const response = await api.post("file/add-file-tag", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("There was an error adding tags to file!", error);
    return { success: false, data: error };
  }
}
export async function confirmStandardization(formData) {
  try {
    const response = await api.post("file/confirm-standardization", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { success: true, data: response };
  } catch (error) {
    console.error("There was an error in record breaking!", error);
    return { success: false, data: error };
  }
}
export async function uploadSikppedList(
  formData,
  _val,
  setProgress,
  setUploadSuccessful
) {
  try {
    const response = await api.post("file/upload-skipped", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      //simulate file upload
      onUploadProgress: function (progressEvent) {
        const total = progressEvent.total;
        const uploaded = progressEvent.loaded;
        _val = Math.round((uploaded * 100) / total);
        setProgress(_val);
        if (uploaded === total) {
          setUploadSuccessful(true);
        }
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("There was an error uploading file!", error);
    return { success: false, data: error };
  }
}
export async function uploadProcessedFile(
  formData,
  _val,
  setProgress,
  setUploadSuccessful
) {
  try {
    const response = await api.post("file/upload-cleaned-file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      //simulate file upload
      onUploadProgress: function (progressEvent) {
        const total = progressEvent.total;
        const uploaded = progressEvent.loaded;
        _val = Math.round((uploaded * 100) / total);
        setProgress(_val);
        if (uploaded === total) {
          setUploadSuccessful(true);
        }
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("There was an error uploading file!", error);
    return { success: false, data: error };
  }
}
export async function removeTags() {
  try {
    const response = await api.delete("file/remove-expired-tags");
  } catch (error) {
    console.error("There was an error !", error);
  }
}

export default api;
