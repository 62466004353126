import React, { useState, useRef } from "react";
import { useQuery } from "react-query";
import { standardize } from "../utils/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../styling/Custom.css";
import "../styling/DoneIcon.css";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Loading from "./Loading";
import { TabView, TabPanel } from "primereact/tabview";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";

export default function Mapping(props) {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [alreadyMapped, setAlreadyMapped] = useState(false);
  const [mappingPreview, setMappingPreview] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [editMapping, setEditMapping] = useState(false);
  const [options, setOptions] = useState(() => {
    const savedOptions = sessionStorage.getItem("savedOptions");
    return savedOptions ? JSON.parse(savedOptions) : [];
  });
  const closeDialog = () => {
    if (!mappingPreview) return;
    setMappingPreview(false);
    setActiveIndex(0);
    setIsTab(true);
  };

  const openDialog = () => {
    setMappingPreview(true);
    setIsTab(false);
  };
  const openPreview = () => {
    setFilePreview(true);
  };
  const [noMapping, setNoMapping] = useState(false);
  //required titles
  let requiredTitles = [
    "First Name",
    "Last Name",
    "Property Address",
    "Property City",
    "Property State",
    "Property Zip",
    "Appended Phone 1",
  ];
  //toggle preview visibilty
  const toggleVisibility = () => {
    setShowPreview(!showPreview);
  };
  //handle the change in dropDown menu with specific index
  const handleChange = (index) => (e) => {
    const newValue = e.target.value;
    if (newValue !== "None" && selectedValue.includes(newValue)) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `Can't map two titles to the same column`,
        life: 3000,
      });
      return;
    }
    setSelectedValue((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = newValue;
      sessionStorage.setItem("savedColValues", JSON.stringify(updatedValues));
      return updatedValues;
    });
  };
  //to edit previous mapping
  const handleEdit = () => {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: `You can now edit the column mappings, feel free to adjust them as needed`,
      life: 5000,
    });
    setEditMapping(false);
  };
  //when the user approves the mapping
  const handleConfirm = (event) => {
    const titles = Object.keys(columns);
    let empty = 0;
    let noneMappings = 0;
    let processing = false;
    Object.entries(selectedValue).forEach(([key, value]) => {
      if (value === null && requiredTitles.includes(titles[key])) {
        empty += 1;
      }
    });
    Object.entries(selectedValue).forEach(([key, value]) => {
      if (value === "None" && requiredTitles.includes(titles[key])) {
        noneMappings += 1;
      }
    });
    if (noneMappings > 0) {
      //the file needs more processing
      processing = true;
      setNoMapping(true);
    }
    if (empty > 0) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `There are ${empty} required columns that need mapping.If no suitable mapping is available, please select "None"`,
        life: 5000,
      });
    } else {
      let maps = {};
      const accept = () => {
        props.onMapped(true);
        props.stepperRef.current.nextCallback();
        if (!processing) {
          Object.entries(selectedValue).forEach(([key, value]) => {
            maps[titles[key]] = value;
          });
          props.mapping(maps);
          if (props.ready) {
            props.ready(true);
          }
        }
      };
      //reject to continue the process
      const reject = () => {
        if (processing) {
          navigate("/options");
        }
      };
      confirmPopup({
        target: event.currentTarget,
        message: !processing ? (
          `Are you certain this is the final mapping? Please note, you won't be able to edit it later`
        ) : (
          <span>
            We've noticed that your file needs some additional processing to be
            broken down into individual records. To ensure everything works
            smoothly, we'll need to clean it before uploading it to the dialer.
            If you have any questions, please contact our customer support team.
            <br /> Would you like to proceed with uploading your list anyway?
          </span>
        ),
        icon: !processing ? "pi pi-exclamation-triangle" : "pi pi-info-circle",
        defaultFocus: !processing ? "accept" : "reject",
        acceptClassName: processing && "p-button-danger",
        accept,
        reject,
      });
    }
  };

  //fetch columns to to standardization step
  const {
    data: columns,
    isLoading: colLoading,
    isFetching: colFetching,
  } = useQuery(
    [
      "standardize",
      localStorage.getItem("client_id"),
      props.file,
      setDataColumns,
      setOptions,
      setSelectedValue,
      setAlreadyMapped,
      setEditMapping,
      props.suggestion,
    ],
    () =>
      standardize(
        props.client,
        props.file,
        setDataColumns,
        setOptions,
        setSelectedValue,
        setAlreadyMapped,
        setEditMapping,
        props.suggestion
      ),
    {
      enabled: props.fetch,
      onSuccess: () => {
        props.setFetch(false);
      },
      cacheTime: 1000 * 60 * 10,
    }
  );
  return !colFetching && !colLoading ? (
    <div className="w-full">
      {isTab ? (
        <div className="flex justify-center mb-2 gap-4">
          <Button className="text-[#009ccf]" onClick={openDialog}>
            Learn How Mapping Works
            <i className="pi pi-question-circle ml-2"></i>
          </Button>
          <Button className="text-[#009ccf]" onClick={openPreview}>
            Preview My File
            <i className="pi pi-eye ml-2"></i>
          </Button>
        </div>
      ) : (
        <Dialog
          header={
            <img
              src={
                "https://res-data-space.fra1.cdn.digitaloceanspaces.com/icons/res-logo-removebg.png"
              }
              alt="logo"
              className="w-[50px] flex justify-center m-[70] p-[40]"
            />
          }
          visible={mappingPreview}
          onHide={closeDialog}
          style={{ width: "50vw", color: "#23455B" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className="card dialog">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel header="" disabled>
                <h2 className="m-0 text-[18px] font-medium">
                  This guide will walk you through the process of mapping your
                  files, explaining each step in detail. If you're already
                  familiar with the process, feel free to skip this tutorial.
                </h2>
                <div className="flex justify-center items-center gap-4 m-2 mt-4">
                  <Button
                    className="bg-[#23455B] rounded-full p-2 px-4 hover:bg-[#168EC2] text-white font-semibold mt-2s "
                    onClick={() => {
                      closeDialog();
                    }}
                  >
                    Skip Tutorial
                  </Button>
                  <Button
                    className="bg-[#23455B] rounded-full p-2 px-4 hover:bg-[#168EC2] text-white font-semibold mt-2s"
                    onClick={() => {
                      setActiveIndex(1);
                    }}
                  >
                    Start Tutorial
                  </Button>
                </div>
              </TabPanel>
              <TabPanel header="" disabled>
                <p className="m-0 text-center text-[17px]">
                  To map the columns in your file to the required dialer titles,
                  simply match the required dialer titles on the left with the
                  corresponding columns from your file using the dropdown menu
                  on the right.
                </p>
                <div className="flex items-center gap-2">
                  <div className="entry rounded-full bg-[#8DE0FF] text-center p-2  lg:w-full md:w-full sm:w-[150px] xs:w-[150px]">
                    Last Name
                  </div>
                  <div className="entry text-center p-2 w-full">
                    <Dropdown
                      filter
                      disabled={true}
                      placeholder={"Input Last Name"}
                      className={` map  lg:w-[300px] md:w-[300px]  sm:w-[200px] xs:w-[200px] bg-[#D3D3D3] rounded-full`}
                    />
                  </div>
                </div>

                <div className="flex pt-4 justify-content-end">
                  <Button
                    label="Next"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    onClick={() => {
                      setActiveIndex(2);
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel header="" disabled>
                <p className="m-0  mb-2 text-center text-[17px]">
                  We have already provided a suggested mapping, but feel free to
                  adjust it as needed to ensure accuracy. If you want to preview
                  the structure of your file, press the button below.
                </p>

                <div className="flex justify-center items-center m-2 mt-4">
                  <Button
                    className="bg-[#009ccf] rounded-full p-2 px-4 hover:bg-[#168EC2] text-white font-semibold mt-2s"
                    onClick={() => {
                      toggleVisibility();
                    }}
                  >
                    Preview my file <i className="pi pi-chevron-down  m-2"></i>
                  </Button>
                </div>
                <div className={`${!showPreview ? "hidden" : ""}`}>
                  <div className={`card flex justify-center overflow-x-auto `}>
                    <DataTable
                      value={dataColumns}
                      showGridlines
                      stripedRows
                      scrollable
                      scrollHeight="300px"
                      tableStyle={{ minWidth: "50rem" }}
                      className="w-full"
                    >
                      {Object.keys(dataColumns[0]).map((key) => (
                        <Column key={key} field={key} header={key} />
                      ))}
                    </DataTable>
                  </div>
                </div>

                <div className="flex pt-4 justify-content-between">
                  <Button
                    label="Back"
                    severity="secondary"
                    icon="pi pi-arrow-left"
                    onClick={() => {
                      setActiveIndex(1);
                    }}
                  />
                  <Button
                    label="Next"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    onClick={() => {
                      setActiveIndex(3);
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel header="" disabled>
                <p className="m-1 text-center text-[17px]">
                  If we couldn't find a suitable mapping, the dropdown will
                  highlight in red for required titles and in black for optional
                  ones.
                </p>
                <div className="flex items-center gap-2">
                  <div className="entry rounded-full bg-[#8DE0FF] text-center p-2 lg:w-full md:w-full sm:w-[150px] xs:w-[150px]">
                    Appended Phone 1
                  </div>
                  <div className="entry text-center p-2 w-full">
                    <Dropdown
                      filter
                      disabled={true}
                      placeholder={"Select Mapping"}
                      className={` map lg:w-[300px] md:w-[300px]  sm:w-[200px] xs:w-[200px]  bg-[#D3D3D3] rounded-full text-red-placeholder`}
                    />
                  </div>
                </div>
                <p className="mt-3 text-center text-[17px]">
                  You’ll need to select the appropriate option from the dropdown
                  menu for the required titles to proceed to the next step.
                </p>

                <div className="flex pt-4 justify-content-between">
                  <Button
                    label="Back"
                    severity="secondary"
                    icon="pi pi-arrow-left"
                    onClick={() => {
                      setActiveIndex(2);
                    }}
                  />
                  <Button
                    label="Next"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    onClick={() => {
                      setActiveIndex(4);
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel header="" disabled>
                <p className="m-0 text-center text-[17px]">
                  If you couldn't find a suitable column name in your file,
                  choose "None" from the dropdown menu
                </p>
                <div className="flex items-center gap-2">
                  <div className="entry rounded-full bg-[#8DE0FF] text-center p-2 lg:w-full md:w-full sm:w-[150px] xs:w-[150px]">
                    Mailing County
                  </div>
                  <div className="entry text-center p-2 w-full">
                    <Dropdown
                      filter
                      disabled={true}
                      placeholder={"None"}
                      className={` map lg:w-[300px] md:w-[300px]  sm:w-[200px] xs:w-[200px] bg-[#D3D3D3] rounded-full`}
                    />
                  </div>
                </div>

                <div className="flex pt-4 justify-content-between">
                  <Button
                    label="Back"
                    severity="secondary"
                    icon="pi pi-arrow-left"
                    onClick={() => {
                      setActiveIndex(3);
                    }}
                  />
                  <Button
                    label="Next"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    onClick={() => {
                      setActiveIndex(5);
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel header="" disabled>
                <p className="m-0 text-[20px] text-center">
                  Great! You’re all set. Ready to get started?
                </p>
                <div className="done-icon">
                  <i className="pi pi-check"></i>
                </div>
                <div className="flex justify-center items-center m-2 mt-4">
                  <Button
                    className="bg-[#009ccf] rounded-full p-2 px-4 hover:bg-[#168EC2] text-white font-semibold mt-2s w-[50%] justify-center "
                    onClick={() => {
                      closeDialog();
                    }}
                  >
                    Let's Begin
                  </Button>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </Dialog>
      )}
      <div className="flex justify-center lg:ml-0 md:ml-0 sm:ml-[4%] xs:ml-[4%] w-full">
        {alreadyMapped ? (
          <>
            <h2 className="text-2xl font-light mx-auto mb-4">
              It seems you've uploaded a similar file before. Here are the
              mappings you previously selected:
            </h2>
          </>
        ) : (
          <h2 className="text-2xl font-light mr-30 mb-4">
            Here are our recommended column mappings, feel free to adjust them
            as needed <br />{" "}
          </h2>
        )}
      </div>
      <Dialog
        header={
          <img
            src={
              "https://res-data-space.fra1.cdn.digitaloceanspaces.com/icons/res-logo-removebg.png"
            }
            alt="logo"
            className="w-[50px] flex justify-center m-[70] p-[40]"
          />
        }
        visible={filePreview}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!filePreview) return;
          setFilePreview(false);
        }}
      >
        <p className="m-0  mb-2 text-center text-[17px]">
          Here’s a quick look at your file’s structure.
        </p>
        <div className={`card flex justify-center overflow-x-auto `}>
          <DataTable
            value={dataColumns}
            showGridlines
            scrollable
            stripedRows
            tableStyle={{ minWidth: "50rem" }}
            className="w-full"
          >
            {Object.keys(dataColumns[0]).map((key) => (
              <Column key={key} field={key} header={key} />
            ))}
          </DataTable>
        </div>
      </Dialog>
      <div className="flex justify-center w-full">
        <div className="grid gap-4 justify-center ml-2 sm:grid-rows-1 sm:grid-cols-2 md:grid-rows-9 md:grid-flow-col lg:grid-rows-9 lg:grid-flow-col">
          {Object.entries(columns).map(
            ([key, value], index) =>
              key !== "list_columns" && (
                <div key={index} className="flex items-center gap-2">
                  <div className="entry rounded-full bg-[#8DE0FF] text-center p-2 w-full">
                    {key}
                  </div>
                  <div className="entry text-center p-2 w-full">
                    <Dropdown
                      filter
                      value={selectedValue[index]}
                      disabled={editMapping}
                      options={options}
                      onChange={handleChange(index)}
                      placeholder={
                        value === null || value === ""
                          ? alreadyMapped
                            ? "None"
                            : "Select mapping"
                          : value
                      }
                      tooltip={
                        (value === null || value === "") &&
                        requiredTitles.includes(key)
                          ? "Required Title"
                          : ""
                      }
                      className={` map w-[300px] bg-[#D3D3D3] rounded-full ${
                        (value === null || value === "") &&
                        requiredTitles.includes(key)
                          ? "text-red-placeholder"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <ConfirmPopup
        style={{
          top: `0`,
          left: `50%`,
          marginTop: "10px",
        }}
        className={`w-[400px] ${noMapping ? "text-red" : ""}`}
      />
      <div className="flex flex-col items-center text-center justify-center space-y-4">
        <Toast ref={toast} />
        {alreadyMapped && editMapping ? (
          <h2 className="text-xl font-light mr-30 ">
            {" "}
            <span>Do you want to proceed with the same mapping?</span>{" "}
          </h2>
        ) : (
          <h2 className="text-xl font-light mr-30 ">
            <span>Do you approve the following mapping?</span>{" "}
          </h2>
        )}
        {alreadyMapped && editMapping ? (
          <div>
            <Button
              label="No"
              text
              aria-label="Filter"
              className=" text-[#8de0ff] hover:text-[#009CCF] mb-4 ml-1 w-[50px] h-[40px]"
              onClick={handleEdit}
            />
            <Button
              label="Yes"
              text
              raised
              aria-label="Filter"
              className=" bg-[#8de0ff]  hover:bg-[#009CCF] mb-4 mr-1 w-[50px] h-[40px]"
              onClick={handleConfirm}
            />
          </div>
        ) : (
          <Button
            icon="pi pi-check"
            rounded
            text
            raised
            aria-label="Filter"
            className=" bg-[#5AC35A]  hover:bg-[#43B045] mb-4 mr-1"
            onClick={handleConfirm}
          />
        )}
      </div>
    </div>
  ) : (
    <Loading message="we're mapping the columns in your list" />
  );
}
