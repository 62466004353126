import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingScreen from "./pages/LandingScreen";
import NotFoundScreen from "./pages/NotFoundScreen";
import Table from "./pages/Table";
import File from "./components/File";
import OptionsScreen from "./pages/client/OptionsScreen";
import MultiStep from "./pages/client/UploadList";
import SignInScreen from "./pages/SignInScreen";
import RegisterScreen from "./pages/client/RegisterScreen";
import { AuthProvider } from "./Authorization/AuthContext";
import ListCriteria from "./pages/client/ListCriteria";
import PrivateRoute from "./Authorization/PrivateRoute";
import SetUp from "./pages/client/SetUp";
import SkipService from "./pages/client/SkipService";
import UnauthorizedScreen from "./pages/UnauthorizedScreen";
import DataOptions from "./pages/worker/DataOptions";
import SkipUpload from "./pages/worker/SkipUpload";
import MultistepSkipTracing from "./pages/client/MultistepSkipTracing";
import WorkerTable from "./pages/worker/WorkerTable";
import DialerStats from "./pages/worker/DialerStats";
import RequestedListUpload from "./pages/worker/RequestedListUpload";
import ProcessedFileUpload from "./pages/worker/ProcessedFileUpload";
import CampaignEdit from "./pages/worker/CampaignName";
import HubSpotForm from "./pages/client/Form";
import ChangePassword from "./pages/client/ChangePassword";
import SummaryPage from "./pages/client/SummaryPage";
import { enableLogs, disableLogs } from "./helpers/logger";
import api from "./utils/api";
import "./index.css";

function App() {
  useEffect(() => {
    // Example: Disable logs in production
    if (api.defaults.baseURL.includes("localhost")) {
      enableLogs();
    } else {
      disableLogs();
    }

    // Example log
    console.log("App has mounted");
  }, []);
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingScreen />} />
          <Route path="/error" element={<NotFoundScreen />} />
          <Route path="/signin" element={<SignInScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/DataSummary" element={<SummaryPage />} />
          <Route
            path="/edit-campaign"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <CampaignEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/database"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <Table user={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/contact-us"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <HubSpotForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/password"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/list-criteria"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <ListCriteria />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-stats"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <DialerStats />
              </PrivateRoute>
            }
          />
          <Route
            path="/res-database"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <Table worker={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-processed-list"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <ProcessedFileUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/file"
            element={
              <PrivateRoute requiredRole={["user", "worker"]}>
                <File />
              </PrivateRoute>
            }
          />
          <Route
            path="/SetUp"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <SetUp />
              </PrivateRoute>
            }
          />
          <Route
            path="/options"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <OptionsScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <MultiStep />
              </PrivateRoute>
            }
          />
          <Route
            path="/skiptrace-service"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <SkipService />
              </PrivateRoute>
            }
          />
          <Route
            path="/skiptrace-upload"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <MultistepSkipTracing />
              </PrivateRoute>
            }
          />
          <Route path="/unauthorized" element={<UnauthorizedScreen />} />
          <Route
            path="/data-options"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <DataOptions />
              </PrivateRoute>
            }
          />
          <Route
            path="/skip-upload"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <SkipUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-requested-list"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <RequestedListUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/database/clients"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <WorkerTable />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
