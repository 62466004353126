import React, { useState, useEffect, useRef } from "react";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Loading(props) {
  return (
    <div>
      <div className="flex flex-col items-center justify-center space-y-4">
        <h2 className="m-4">Please wait {props.message}</h2>
        <ProgressSpinner
          style={{ width: "100px", height: "100px", margin: "12px" }}
        />
      </div>
    </div>
  );
}
