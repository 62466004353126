import { React } from "react";
import Header from "../../components/Header";
import Card from "../../components/OptionCard";
import upload from "../../imgs/upload-cropped.svg";
import database from "../../imgs/database-cropped.svg";
import profile from "../../imgs/profile-cropped.svg";
import stats from "../../imgs/stats-svgrepo-com-cropped.svg";
import list from "../../imgs/file-check-svgrepo-com-cropped.svg";
import process from "../../imgs/process-on-vm-svgrepo-com-cropped.svg";

export default function DataOptions() {
  const client_name = localStorage.getItem("firstName");

  return (
    <div>
      <Header
        username={client_name}
        profileLink="/data-options"
        options={true}
        logoutVisible={true}
        profileImage={profile}
      />
      <div className="flex items-center justify-center m-4">
        <div className="grid xs:grid-cols-1  sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-x-12 gap-y-6 ">
          <div className=" justify-center flex items-center">
            <Card
              image={list}
              width={"full"}
              title="Upload a list requested by a client"
              buttonText="Upload"
              destination={"/upload-requested-list"}
              steps={false}
            />
          </div>
          <div className=" justify-center flex items-center">
            <Card
              image={process}
              width={"full"}
              title="Upload a list after processing"
              buttonText="Upload"
              destination={"/upload-processed-list"}
              steps={false}
            />
          </div>
          <div className="w-full justify-center flex items-center">
            <Card
              image={upload}
              width={"full"}
              title="Upload skiptraced list"
              buttonText="Upload"
              destination={"/skip-upload"}
              steps={false}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center m-4">
        <div className="grid xs:grid-cols-1  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-12 gap-y-6 justify-center">
          <div className="w-full justify-center flex items-center">
            <Card
              image={stats}
              width={"full"}
              title="Upload dialer stats"
              buttonText="Upload Stats"
              destination={"/upload-stats"}
              steps={false}
            />
          </div>
          <div className=" justify-center flex items-center">
            <Card
              image={database}
              width={"full"}
              title="Go to database"
              buttonText="Go"
              destination={"/database/clients"}
              steps={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
