// logger.js

// Flag to toggle logging
export let enableLogging = true;

// Save original console methods
const originalConsoleLog = console.log;

// Override console.log
console.log = function (...args) {
  if (enableLogging) {
    originalConsoleLog.apply(console, args);
  }
};

// Function to enable logging
export const enableLogs = () => {
  enableLogging = true;
};

// Function to disable logging
export const disableLogs = () => {
  enableLogging = false;
};
